const cotton = [
  18892, 18949, 18973, 38417, 38625, 38001, 37542, 36949, 37530, 36745, 37518, 19123, 19126, 36739,
  36754, 19162, 19171, 19609, 36748, 19219, 19285, 19606, 19366, 19369, 19612, 19567, 19438, 19450,
  19453, 19456, 19510, 37551, 37587, 38384, 38474, 38586, 38648, 19420, 19447, 19534, 19537,
]

const wolle = [
  18928, 18883, 37548, 18976, 18961, 36742, 37578, 37563, 38023, 37593, 37599, 37590, 19600, 19066,
  19075, 19108, 19111, 19783, 19780, 19159, 19582, 19195, 19789, 19204, 19267, 19273, 19078, 19276,
  19282, 19579, 19096, 19099, 19336, 19342, 19129, 19378, 19387, 19390, 19396, 19399, 19981, 19174,
  19411, 19177, 19189, 19432, 19435, 19489, 19495, 19498, 19507, 19231, 19234, 19519, 19543, 37566,
  19255, 19258, 19270, 37581, 19279, 38540, 19315, 19333, 38592, 19321, 38662, 19339, 38668, 38675,
  38689, 19360, 38740, 38795, 19372, 38792, 38807, 19375, 38861, 19393, 19444, 19459, 19465, 19480,
  19483, 19492, 19522, 19546,
]

const washable = [20297, 20300, 20303, 20306, 20309]

const handmade = ['19633', '37116', '19636', '19642', '19639', '37602']

const sustainability = ['38552', '38429']

const sample = [
  11932, // 15x15cm - Live/Staging
  10771, // 10x10cm - Live/Staging
]

export const checkIsCotton = (productNumber: number) => {
  return cotton.includes(productNumber)
}
export const checkIsWolle = (productNumber: number) => {
  return wolle.includes(productNumber)
}

export const checkIsWashable = (productNumber: number) => {
  return washable.includes(productNumber)
}

export const checkIsHandmade = (productNumber: string) => {
  return handmade.includes(productNumber)
}

export const checkHasSustainability = (productNumber: string) => {
  return sustainability.includes(productNumber)
}

export const checkIsSample = (productNumber: number) => {
  return sample.includes(productNumber)
}
