import { useTranslation } from 'next-i18next'
import React from 'react'

const FeatureHeadline: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <p
      className={`relative text-[15px] font-extralight leading-[18px] text-sg-dark-grey before:absolute before:-left-1 before:bottom-0 before:block before:content-[','] first:before:hidden lg:text-15-22-sg`}
    >
      {children}
    </p>
  )
}

const FeaturesSection: React.FC<{
  isWolle?: boolean
  isCotton?: boolean
  hasSustainability?: boolean
  isHandmade?: boolean
  isWashable?: boolean
}> = ({ isWolle, isCotton, hasSustainability, isHandmade, isWashable }) => {
  const { t } = useTranslation()
  return (
    <div className="flex gap-1 ">
      {isWolle && <FeatureHeadline>{t('wool')}</FeatureHeadline>}
      {isCotton && !isWolle && <FeatureHeadline>{t('cotton')}</FeatureHeadline>}
      {hasSustainability && <FeatureHeadline>{t('certified')}</FeatureHeadline>}
      {isHandmade && !hasSustainability && <FeatureHeadline>{t('handmade')}</FeatureHeadline>}
      {isWashable && !isHandmade && !hasSustainability && (
        <FeatureHeadline>{t('washable')}</FeatureHeadline>
      )}
    </div>
  )
}

export default FeaturesSection
