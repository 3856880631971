import {
  ConfigurableCartItem,
  ConfigurableProduct,
  useAddToCartMutation,
  useCartPageQuery,
  useFetchCartHeaderQuery,
} from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { createMagentoClient } from '~/graphql/magentoClient'
import ProductCardIcon from './Icons/ProductCardIcon'
import { toast } from 'react-toastify'
import { useTranslation } from 'next-i18next'
import { CART_ID } from '~/config/constants'
import { checkIsSample } from '~/lib/getProductFeature'

interface SampleButtonProps {
  product: ConfigurableProduct
  cartId?: string
  onSampleAddedToCart?: () => void
}

const SampleButton: React.FC<SampleButtonProps> = ({ product, cartId, onSampleAddedToCart }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const magentoClient = createMagentoClient({ usePost: true, queryClient })

  const { data: cartData } = useCartPageQuery(
    magentoClient,
    { cartId: cartId || '' },
    { enabled: !!cartId && cartId !== '' },
  )

  const [isSampleAdding, setIsSampleAdding] = useState(false)
  const getSampleUid = (): string => {
    const attributes = product?.configurable_options?.[0]
    let sampleUid = ''

    if (attributes?.attribute_code === 'size') {
      attributes?.values?.forEach((attr) => {
        if (checkIsSample(attr?.value_index as number)) sampleUid = attr?.uid as string
      })
    }

    return sampleUid
  }
  const getSampleboxSlotsLeft = (): number => {
    let samplesQty = 0

    ;(cartData?.cart?.items as ConfigurableCartItem[])?.forEach((item) => {
      if (checkIsSample(item?.configurable_options?.[0]?.value_id as number))
        samplesQty += item.quantity
    })

    return samplesQty
  }

  const addToCartMutation = useAddToCartMutation(magentoClient, {
    onError: async () => {
      await queryClient.invalidateQueries([CART_ID])
      await queryClient.invalidateQueries(['session'])
    },
  })

  const addSampleboxToCart = async () => {
    // Update the flag
    setIsSampleAdding(true)
    // Check if any slots left on the samplebox
    if (getSampleboxSlotsLeft() >= 5) {
      // Inform user from the error
      toast.error(t('Your sample box is full'))
      // Tell parent component to open samplebox flyout
      onSampleAddedToCart?.()
      // Update the flag
      setIsSampleAdding(false)
    } else {
      // Check if sample already added to the cart
      const isProductAlreadyAdded =
        (cartData?.cart?.items as ConfigurableCartItem[])?.findIndex(
          (item) =>
            product?.sku === item?.product?.sku &&
            checkIsSample(item?.configurable_options?.[0]?.value_id as number),
        ) !== -1
      if (isProductAlreadyAdded) {
        // Inform user from the error
        toast.error(t('This sample is already in your sample box'))
        // Tell parent component to open samplebox flyout
        onSampleAddedToCart?.()
        // Update the flag
        setIsSampleAdding(false)
      } else {
        // Add the sample to the cart
        addToCartMutation
          .mutateAsync({
            cartId: cartId as string,
            cartItems: [
              {
                quantity: 1,
                selected_options: [getSampleUid()],
                sku: product?.sku as string,
              },
            ],
          })
          .then(async (data) => {
            if (data?.addProductsToCart?.user_errors?.length || 0 > 0) {
              // Inform user from the error
              toast.error(data?.addProductsToCart?.user_errors?.[0]?.message)
            } else {
              // Flush query caches
              await queryClient.refetchQueries(useCartPageQuery.getKey({ cartId: cartId || '' }))
              await queryClient.invalidateQueries(
                useFetchCartHeaderQuery.getKey({ cartId: cartId || '' }),
              )
              // Inform user from success operation
              toast.success(t('The sample has been added to your sample box'))
              // Tell parent component to open samplebox flyout
              onSampleAddedToCart?.()
            }
            // Update the flag
            setIsSampleAdding(false)
          })
          .catch(async (error) => {
            // Flush query caches
            await queryClient.refetchQueries(useCartPageQuery.getKey({ cartId: cartId || '' }))
            await queryClient.invalidateQueries(
              useFetchCartHeaderQuery.getKey({ cartId: cartId || '' }),
            )
            // Inform user from the error
            toast.error(error?.message)
            // Update the flag
            setIsSampleAdding(false)
          })
      }
    }
  }

  return (
    <div className="mt-4 flex justify-center py-2 text-center">
      {getSampleUid() !== '' && (
        <button
          className="items-top flex w-full underline decoration-2 underline-offset-8"
          disabled={isSampleAdding}
          onClick={addSampleboxToCart}
        >
          <ProductCardIcon name="sample-2" className="h-6 w-6" />
          <span className="ml-1.5 text-p-small">
            {t(isSampleAdding ? 'Will be added' : 'Add sample to box')}
          </span>
        </button>
      )}
    </div>
  )
}

export default SampleButton
