import { useTranslation } from 'next-i18next'
import React from 'react'

interface ProductCardBadgesProps {
  newBadge?: boolean
  saleBadge?: boolean
  bestsellerBadge?: boolean
}

const ProductCardBadges: React.FC<ProductCardBadgesProps> = ({
  newBadge,
  saleBadge,
  bestsellerBadge,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {newBadge && (
        <p className="mr-2.5 inline-block text-[15px] capitalize leading-[18px] text-sg-orange last:mr-0 lg:text-15-22-sg">
          {t('new')}
        </p>
      )}
      {saleBadge && (
        <p className="mr-2.5 inline-block text-[15px] capitalize leading-[18px] text-sg-orange last:mr-0 lg:text-15-22-sg">
          {t('sale')}
        </p>
      )}
      {bestsellerBadge && (
        <p className="mr-2.5 inline-block text-[15px] capitalize leading-[18px] text-sg-sand last:mr-0 lg:text-15-22-sg">
          {t('bestseller')}
        </p>
      )}
    </div>
  )
}

export default ProductCardBadges
