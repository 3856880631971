import clsx from 'clsx'
import React from 'react'
import { Link } from '~/elements/Link/Link'
import { Image } from '@next/image'
import ProductCardIcon from './Icons/ProductCardIcon'
import { Maybe } from '@magentoTypes'

interface ProductCardInterface {
  backgroundImage?: Maybe<string>
  dataLayerEvent?: () => void
  alt: string
  productUrl: string
  productName: string
  mainImage: string
  hasPriority?: boolean
  isSlider?: boolean
  hasAR?: Maybe<string>
}

const ProductCardTop: React.FC<ProductCardInterface> = ({
  backgroundImage,
  dataLayerEvent,
  alt,
  productUrl,
  productName,
  mainImage,
  hasPriority,
  isSlider,
  hasAR,
}) => {
  return (
    <div className={clsx('group relative aspect-[4/5] pt-5 lg:z-auto')}>
      {backgroundImage ? (
        <div
          className={clsx(
            'pointer-events-none absolute top-0 left-0 z-10 hidden h-full w-full pt-5 opacity-0 transition-opacity duration-500 ease-in-out group-hover:pointer-events-auto group-hover:opacity-100 lg:block',
          )}
        >
          <div className="h-full w-full overflow-hidden rounded-[5px]">
            <button className="block h-full w-full" onClick={dataLayerEvent && dataLayerEvent}>
              <Link href={productUrl} title={productName} className="relative block h-full w-full">
                <Image
                  className="block"
                  src={backgroundImage}
                  alt={alt}
                  fill
                  style={{ objectFit: 'cover' }}
                  sizes={
                    isSlider ? '(max-width: 1024px) 75vw, 16vw' : '(max-width: 1024px) 50vw, 25vw'
                  }
                />
              </Link>
            </button>
          </div>
        </div>
      ) : null}
      <button className="h-full w-full max-w-full" onClick={() => {}}>
        <Link
          href={productUrl}
          title={productName}
          className="relative block h-full w-full overflow-hidden rounded-[5px]"
        >
          <Image
            //TODO: Remove fallback image after fallback is set in magento
            src={mainImage}
            alt={alt}
            fill
            style={{ objectFit: 'contain' }}
            sizes={isSlider ? '(max-width: 1024px) 75vw, 16vw' : '(max-width: 1024px) 50vw, 25vw'}
            priority={!!hasPriority}
            className="aspect-[4/5]"
            // to remove if not nessessary
          />
        </Link>
      </button>
      {hasAR && (
        <div className="pointer-events-none absolute top-[30px] right-2.5 z-10 mr-5">
          <ProductCardIcon name="ar-2" className="h-4.5 w-4.5 lg:h-6 lg:w-6" />
        </div>
      )}
    </div>
  )
}

export default ProductCardTop
