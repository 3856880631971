import { ConfigurableProduct } from '@magentoTypes'
import clsx from 'clsx'
import { getRelatedSkus } from '~/modules/PDP/lib/getRelatedSkus'
import { RATING_CLASS_NAME, RATING_PLACEHOLDER_CLASS_NAME } from '../lib/constants'
import { Icon } from '~/elements/Icon/Icon'

type Props = {
  product: ConfigurableProduct
  withText?: boolean
}

const ProductRating: React.FC<Props> = ({ product, withText }) => {
  return (
    <div
      key={`product_rating_${product.sku}`}
      className={clsx(RATING_CLASS_NAME, 'flex min-w-0 gap-x-0.5', {
        '[&_span]:hidden': !withText,
      })}
      data-sku={getRelatedSkus(product).join(';')}
    >
      <div
        className={`ruk_rating_placeholder flex animate-pulse gap-x-0.5 ${RATING_PLACEHOLDER_CLASS_NAME}`}
      >
        {[0, 1, 2, 3, 4].map((i) => (
          <Icon
            key={`loding_star_${i}`}
            name="single-star"
            className="h-3 w-3 lg:h-[18px] lg:w-[18px]"
            style={{ color: '#CFCFCF' }}
          />
        ))}
      </div>
    </div>
  )
}

export { ProductRating }
