import { useSession } from '~/hooks/useSession'
import { UserAddToWishlist } from './components/UserAddToWishlist'
import { GuestAddToWishlist } from './components/GuestAddToWishlist'

type Props = {
  className?: string
  sku: string
  isProductCard?: boolean
  withCioAttributes?: boolean
  trackingHandler?: () => void
}

export const AddToWishlist = ({
  className,
  sku,
  isProductCard,
  withCioAttributes,
  trackingHandler,
}: Props) => {
  const [session] = useSession()
  return (
    <>
      {session ? (
        <UserAddToWishlist
          className={className}
          trackingHandler={trackingHandler}
          sku={sku}
          isProductCard={isProductCard}
          withCioAttributes={withCioAttributes}
        />
      ) : (
        <GuestAddToWishlist
          className={className}
          trackingHandler={trackingHandler}
          sku={sku}
          isProductCard={isProductCard}
          withCioAttributes={withCioAttributes}
        />
      )}
    </>
  )
}
