import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'next-i18next'
import { useMedia } from '~/hooks/useMedia'
import { createMagentoClient } from '~/graphql/magentoClient'
import {
  useGetWishlistIdQuery,
  useAddToWishlistMutation,
  useRemoveItemsFromWishlistMutation,
  useFetchUserWishlistQuery,
} from '@magentoTypes'
import DotLoader from 'react-spinners/DotLoader'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { WishIcon } from './WishIcon'

type Props = {
  className?: string
  sku: string
  isProductCard?: boolean
  withCioAttributes?: boolean
  trackingHandler?: () => void
}

export const UserAddToWishlist = ({
  className,
  sku,
  isProductCard,
  withCioAttributes,
  trackingHandler,
}: Props) => {
  const [isOverWish, setIsOverWish] = useState(false)
  const { t } = useTranslation()
  const isDesktop = useMedia('lg')
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const mutationClient = createMagentoClient({ queryClient, usePost: true, locale: finalLocale })

  const magentoClient = createMagentoClient({
    usePost: false,
    queryClient,
    locale: finalLocale,
  })
  const addToWishlist = useAddToWishlistMutation(mutationClient, {
    onSettled: async (data, error) => {
      if (data) {
        await queryClient.refetchQueries(useGetWishlistIdQuery.getKey())
        await queryClient.refetchQueries(useFetchUserWishlistQuery.getKey())
        toast.success(t('Item added to wishlist'))
      } else {
        toast.error(error?.message)
      }
    },
  })
  const removeFromWishlist = useRemoveItemsFromWishlistMutation(mutationClient, {
    onSettled: async (data, error) => {
      if (data) {
        await queryClient.refetchQueries(useGetWishlistIdQuery.getKey())
        await queryClient.refetchQueries(useFetchUserWishlistQuery.getKey())
        toast.success(t('Item removed from wishlist'))
      } else {
        toast.error(error?.message)
      }
    },
  })
  const { data } = useGetWishlistIdQuery(magentoClient)
  const wishlistId = data?.customer?.wishlists?.[0]?.id as string

  const wishItemId = useMemo(() => {
    return data?.customer?.wishlists?.[0]?.items_v2?.items?.find(
      (item) => item?.product?.sku === sku,
    )?.id
  }, [data?.customer?.wishlists, sku])

  const handleClick = () => {
    trackingHandler && trackingHandler()
    if (wishlistId) {
      if (wishItemId) {
        removeFromWishlist.mutate({
          wishlistId,
          wishlistItemsIds: [wishItemId],
        })
      } else {
        addToWishlist.mutate({
          wishlistId,
          wishlistItems: [
            {
              sku,
              quantity: 1,
            },
          ],
        })
      }
    }
  }
  const trueHover = () => {
    if (!isDesktop) return
    setIsOverWish(true)
  }
  const falseHover = () => {
    if (!isDesktop) return
    setIsOverWish(false)
  }
  if (!hasMounted) {
    return null
  }
  return (
    <button
      title={!!wishItemId ? t('Remove from wishlist') : t('Add to wishlist')}
      className={clsx('h-full w-full', className)}
      onMouseEnter={trueHover}
      onMouseLeave={falseHover}
      onClick={handleClick}
      data-cnstrc-btn={withCioAttributes ? 'add_to_wishlist' : undefined}
    >
      {/* {addToWishlist.isLoading || removeFromWishlist.isLoading ? ( */}
      {/* // <DotLoader loading={true} size={20} color={'#909090'} /> */}
      <WishIcon
        active={!!wishItemId}
        isHovered={isOverWish}
        isProductCard={isProductCard}
        loading={addToWishlist.isLoading || removeFromWishlist.isLoading}
      />
      {/* ) : (
        <WishIcon active={!!wishItemId} isHovered={isOverHeart} isProductCard={isProductCard} />
      )} */}
    </button>
  )
}
