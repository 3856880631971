import React from 'react'
import ContentLoader from 'react-content-loader'

const Loader: React.FC<{ height: number | string }> = ({ height }) => (
  <ContentLoader
    speed={1}
    width="100%"
    height={height}
    backgroundColor="#F4F4F4"
    foregroundColor="#CACACA"
    gradientRatio={1}
    className="rounded-[5px]"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height={height} />
  </ContentLoader>
)

export default Loader
