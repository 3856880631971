export const RATING_CLASS_NAME = 'ruk_rating_snippet'
export const RATING_PLACEHOLDER_CLASS_NAME = 'ruk_rating_placeholder'

export const REVIEWS_LANG_CONFIG: Record<string, string> = {
  benuta_de: 'de',
  benuta_at: 'de',
  benuta_chde: 'de',
  benuta_chfr: 'fr',
  benuta_chit: 'it',
  benuta_fr: 'fr',
  benuta_it: 'it',
  benuta_es: 'es',
  benuta_co_uk: 'en',
  benuta_nl: 'nl',
  benuta_pl: 'pl',
  benuta_dk: 'da',
  benuta_se: 'sv',
  benuta_pt: 'pt',
  benuta_fi: 'fi',
  benuta_cz: 'cs',
  benuta_no: 'no',
  benuta_eu: 'en',
}

export const REVIEWS_STORE_CONFIG: Record<string, string> = {
  benuta_at: 'benuta.at',
}
