import { ConfigurableProduct, Maybe, PriceRange } from '@magentoTypes'

export const getCioVariationId = (
  product: ConfigurableProduct,
  variantIndex: number = 0,
): string | undefined => {
  if (
    product?.sku &&
    product?.variants?.length &&
    product?.variants?.[variantIndex]?.product?.sku
  ) {
    return `${product?.variants?.[variantIndex]?.product?.sku}-c-${product?.sku}`
  }

  return undefined
}

export const getCioProductPrice = (
  priceRange: PriceRange | undefined,
): Maybe<number> | undefined => {
  const hasSalePrice =
    priceRange?.minimum_price?.final_price?.value !==
    priceRange?.minimum_price?.regular_price?.value

  if (hasSalePrice) {
    return priceRange?.minimum_price?.final_price.value
  } else {
    return priceRange?.minimum_price?.regular_price.value
  }
}
