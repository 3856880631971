import { useSpring, a } from '@react-spring/web'

type Props = {
  active: boolean
  isHovered: boolean
  isProductCard?: boolean
  loading?: boolean
}
export const WishIcon = ({ active, isHovered, isProductCard, loading }: Props) => {
  const spring = useSpring({
    fill:
      active && !isHovered
        ? '#000000'
        : active && isHovered
        ? '#CEC9C1'
        : !active && isHovered
        ? '#000000'
        : 'transparent',
  })

  const laoder = useSpring({
    from: { opacity: 0.5, fill: '#000000' },
    to: { opacity: 0.75, fill: '#000000' },
    loop: { reverse: true },
    config: { duration: 400 },
  })

  const applyStyle = loading ? laoder : spring

  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <a.path
        d="M5.33337 18.2338V5C5.33337 4.44772 5.78109 4 6.33337 4H17.6667C18.219 4 18.6667 4.44772 18.6667 5V18.2338C18.6667 19.0111 17.8187 19.4912 17.1522 19.0913L12.5145 16.3087C12.1979 16.1187 11.8022 16.1187 11.4855 16.3087L6.84787 19.0913C6.18135 19.4912 5.33337 19.0111 5.33337 18.2338Z"
        strokeWidth="1.5"
        stroke="#000000"
        {...applyStyle}
      />
    </svg>
  )
}
